import React from 'react';
import PT from 'prop-types';

const AuthContext = React.createContext();

export default AuthContext;

export class AuthProvider extends React.Component {
  state = {
    code: 'Singer1',
    codeInputted: false,
    codeInputError: false,
    initialDelay: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ initialDelay: false });
    }, 100);
  }

  attemptCodeInput = (codeAttempt) => {
    const { code } = this.state;
    this.setState({
      codeInputted: codeAttempt === code,
      codeInputError: codeAttempt !== code,
    });
  };

  render() {
    const { children } = this.props;
    const { codeInputted, codeInputError, initialDelay } = this.state;
    return (
      <AuthContext.Provider
        value={{
          codeInputted,
          codeInputError,
          attemptCodeInput: this.attemptCodeInput,
          initialDelay,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PT.object.isRequired,
};
