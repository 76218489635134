import React from "react";
import PT from "prop-types";
import { AuthProvider } from "./src/contexts/AuthContext";

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);

wrapRootElement.propTypes = {
  element: PT.object.isRequired,
};
